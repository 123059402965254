import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import pdfInfoBooklet from "../../../../assets/pdfs/prohibited_16_11_2021_esp.pdf";
import axios from "axios";

function GoodsNotAccepted() {
  const [tRestrictions] = useTranslation("restrictions");
  const idComponent = "50c1bc14-7da9-4592-a7a8-009b58af3621";
  const [dataInformation, setDataInformation] = useState(() => {
    const newData = {};
    for (let i = 1; i <= 20; i++) {
      newData[`text-${i}`] = "";
    }
    newData["titulo"] = "";
    return {
      "Mercancías peligrosas no aceptadas por Aeroméxico Cargo": newData,
    };
  });

  const showInfoBooklet = () => {
    window.open(pdfInfoBooklet, "_blank")
  };

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idComponent}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setDataInformation(
          response.data.textWeb.text_es["Mercancías peligrosas no aceptadas por Aeroméxico Cargo"]
        );
      } else {
        setDataInformation(
          response.data.textWeb.text_us["Mercancías peligrosas no aceptadas por Aeroméxico Cargo"]
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTexts();
  }, []);

  return (
    <div>
      <p className="mb-md-4 my-5 text-center text-premium-blue fs-5">
        {dataInformation["titulo"] ?? tRestrictions("goods-not-accepted.goods-not-accepted")}
      </p>
      <div className="container px-5 mb-5 d-flex justify-content-center">
        <div className="col-10">
          <div className="row">
            <div className="col-12 col-md-6 pt-3 border-end">
              <ul className="li-space text-premium-blue fs-12">
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-1"] ?? tRestrictions("goods-not-accepted.text-1")}
                  </span>
                  <p className="m-0">
                    {dataInformation["text-2"] ?? tRestrictions("goods-not-accepted.text-2")}
                  </p>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-3"] ?? tRestrictions("goods-not-accepted.text-3")}
                  </span>
                  <p className="m-0">
                    {dataInformation["text-4"] ?? tRestrictions("goods-not-accepted.text-4")}
                  </p>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-5"] ?? tRestrictions("goods-not-accepted.text-5")}
                  </span>
                  <p className="m-0">
                    {dataInformation["text-6"] ?? tRestrictions("goods-not-accepted.text-6")}.
                  </p>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-7"] ?? tRestrictions("goods-not-accepted.text-7")}
                  </span>
                  <p className="m-0">
                    {dataInformation["text-8"] ?? tRestrictions("goods-not-accepted.text-8")}
                  </p>
                  <p className="m-0">
                    {dataInformation["text-9"] ?? tRestrictions("goods-not-accepted.text-9")}
                  </p>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-10"] ?? tRestrictions("goods-not-accepted.text-10")}
                  </span>
                  <p className="m-0">
                    {dataInformation["text-11"] ?? tRestrictions("goods-not-accepted.text-11")}
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 pt-3">
              <ul className="li-space text-premium-blue fs-12">
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-12"] ?? tRestrictions("goods-not-accepted.text-12")}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-13"] ?? tRestrictions("goods-not-accepted.text-13")}
                  </span>
                  <p className="m-0">
                    {dataInformation["text-14"] ?? tRestrictions("goods-not-accepted.text-14")}
                  </p>
                </li>
                <li>
                  {dataInformation["text-15"] ?? tRestrictions("goods-not-accepted.text-15")}
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-16"] ?? tRestrictions("goods-not-accepted.text-16")}
                  </span>
                </li>
                <li>
                  {dataInformation["text-17"] ?? tRestrictions("goods-not-accepted.text-17")}
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-18"] ?? tRestrictions("goods-not-accepted.text-18")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="text-center mt-5 mb-md-4 fs-5">
            <a
              href="#"
              className="text-mexican-pink"
              id="linkGoodsNotAccepted"
              onClick={showInfoBooklet}
            >
              {dataInformation["text-19"] ?? tRestrictions("goods-not-accepted.text-19")}
            </a>
          </div>
          <div className="d-flex justify-content-center align-items-center py-2">
            <Link id="btnQuoteRestrictions" className="btn-blue" to="/quote">
              {dataInformation["text-20"] ?? tRestrictions("goods-not-accepted.text-20")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoodsNotAccepted;
